/* eslint-disable security/detect-unsafe-regex */
import React, {forwardRef, RefObject, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import {getProduct, getProducts, IProduct} from '../../../../api/products'
import SimpleTitle from '../../../../components/common/SimpleTitle'
import styled from 'styled-components'
import {getAppCart, getSheet, postCart, putCart} from '../../../../api/cart'
import ImageView from '../../../../components/ImageView'
import OrderModal from '../../cart/components/orderModal'
import PageContext from '../../../../contexts/PageContext'
import {getOrderSheet, postOrderSheet} from '../../../../api/orderSheets'
import {CheckCircleOutlined, DeleteOutlined, PlusOutlined, QuestionOutlined} from '@ant-design/icons'
import {useRecoilState, useRecoilValue} from 'recoil'
import {cartTotalState} from '../../../../states/cartTotal.state'
import {useNavigate} from 'react-router'
import {userState} from '../../../../states/user.state'
import {useWatch} from 'antd/es/form/Form'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProduct>
  onAction: (type: ActionType, record: Partial<IProduct>) => void | Promise<void>
}

interface IRecord extends IProduct {}

function Description({record, product}: {record: IRecord; product: any}) {
  const batteryTypeArr = record?.products?.map(({batteryType}: any) => batteryType)
  const noDupBatteryTypeArr = batteryTypeArr?.filter((element, index) => {
    return batteryTypeArr.indexOf(element) === index
  })

  return (
    <Row gutter={25}>
      <Col>
        <ImageView src={record?.titleImage} width={273} height={273} />
      </Col>
      <Col>
        <SimpleTitle
          title={
            <div>
              <div>{record.name}</div>
              <div style={{fontSize: 15}}>{record.korean}</div>
            </div>
          }
          subTitle={record?.info?.brand?.name}
        />
        <Space direction="vertical">
          <Descriptions style={{width: 400}} size="small" column={1} bordered title="상품 정보">
            <Descriptions.Item span={1} label="브랜드">
              {record?.info?.brand?.name || '-'}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="제품 유형">
              {(record?.info?.orderType?.korean &&
                `${record?.info?.orderType?.name}(${record?.info?.orderType?.korean})`) ||
                record?.info?.orderType?.name ||
                '-'}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="제품 플랫폼">
              {(record?.info?.salesPlatform?.korean &&
                `${record?.info?.salesPlatform?.name}(${record?.info?.salesPlatform?.korean})`) ||
                record?.info?.salesPlatform?.name ||
                '-'}
            </Descriptions.Item>
            {record?.info?.batteryType && (
              <Descriptions.Item span={1} label="배터리 타입">
                {noDupBatteryTypeArr.join(', ')}
              </Descriptions.Item>
            )}
          </Descriptions>
          <LabelHorizontalFormItem label="착용자명 :" name="clientName">
            <Input style={{width: '100%'}} />
          </LabelHorizontalFormItem>
        </Space>
      </Col>
    </Row>
  )
}

function Show({record: {data: recordRaw, orderType}}: {record: any}) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const orderModalRef = useRef<BasicTableModalRef>(null)
  const {state, apiHandler} = useContext(PageContext)
  const [selected, setSelected] = useState<any>({receiver: [], charger: [], hook: []})
  const [products, setProducts] = useState<any>([])
  const [product, setProduct] = useState<any>(null)
  const [record, setRecord] = useState<any>({})
  const [mostQuantityId, setMostQuantityId] = useState<number>(-1)
  const [quantity, setQuantity] = useState<number>(-1)
  const [coupons, setCoupons] = useState<any>([])
  const [colorLoading, setColorLoading] = useState<boolean>(false)
  const [optionLoading, setOptionLoading] = useState<boolean>(false)
  const [, setCartTotal] = useRecoilState(cartTotalState)
  const navigator = useNavigate()
  const isRecordFetched = useRef(false)
  const user: any = useRecoilValue(userState)
  const selectedCoupons = useWatch('couponIds', form)
  const selectedCharges = useWatch('chargeIds', form)
  const [quantities, setQuantities] = useState({})
  const [totalQuantity, setTotalQuantity] = useState(0)
  const maxReceivers = orderType?.receiverCount * quantity
  const [noQuantityAgree, setNoQuantityAgree] = useState(false)

  useEffect(() => {
    // Calculate the total quantity whenever the quantities change
    const total: any = Object.values(quantities).reduce((acc: any, curr: any) => acc + (curr || 0), 0)
    setTotalQuantity(total)
  }, [quantities])

  const handleQuantityChange = (value, index) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [index]: value
    }))
  }

  const isNotInputedAll = useMemo(() => {
    const noColor = !selected?.color
    const noSale = !product?.onSale
    const noEnoughQuantity = product?.safetyQuantity > product?.quantity && !noQuantityAgree

    const noAllChargeSelected =
      (!!selected.charger?.length &&
        ((!!selected?.charger[0] && Math.floor(orderType?.chargeCount * quantity) !== selected?.charger?.length) ||
          (!selected?.charger[0] && !!record.chargers?.length && !!orderType?.chargeCount))) ||
      (!selected.charger?.length && !!record.charges?.length && !!orderType?.chargeCount)

    const noAllHooksSelected =
      (!!selected.hook?.length &&
        ((!!selected?.hook[0] && Math.floor(orderType?.hookCount * quantity) !== selected?.hook?.length) ||
          (!selected?.hook[0] && !!record.hooks?.length && !!orderType?.hookCount))) ||
      (!selected.hook?.length && !!record.hooks?.length && !!orderType?.hookCount)

    const noReceiverQuantityOvered =
      (!selected?.receiver?.length || totalQuantity !== maxReceivers) && !!record?.receivers?.length

    const noAllAccsSelected =
      (!!selected.acc?.length &&
        ((!!selected?.acc[0] && Math.floor(orderType?.accCount * quantity) !== selected?.acc?.length) ||
          (!selected?.acc[0] && !!record.accs?.length && !!orderType?.accCount))) ||
      (!selected.acc?.length && !!record.accs?.length && !!orderType?.accCount)

    const noQuantityAgreed =
      (!product?.quantity || noEnoughQuantity || quantity > product?.quantity) &&
      !noQuantityAgree &&
      orderType?.name !== 'ITE'

    return (
      noColor ||
      noSale ||
      noEnoughQuantity ||
      noAllChargeSelected ||
      noAllHooksSelected ||
      noReceiverQuantityOvered ||
      noAllAccsSelected ||
      noQuantityAgreed
    )
  }, [selected, product, orderType, record, quantity, totalQuantity, maxReceivers, noQuantityAgree])

  async function handleFinish(values: any, isDirectBuy?: boolean) {
    let selectedId: number = 0

    const {receiverIds} = values
    const finalReceiverIds: any = []

    if (receiverIds) {
      receiverIds.forEach(({receiverId}, index) => {
        const quantity = quantities[index] || 0
        for (let i = 0; i < quantity; i++) {
          finalReceiverIds.push(receiverId)
        }
      })

      values.receiverIds = finalReceiverIds
    }

    if (values.chargerIds) {
      values.chargerIds = values.chargerIds.filter((id) => id !== -1)
      if (!values.chargerIds.length) delete values.chargerIds
    }

    if (values.receiverIds) {
      values.receivers = values.receiverIds
        .map((targetId) => {
          const targetReceiver = record.receivers.find(({id}) => id === targetId)
          if (targetReceiver && record?.info?.orderType?.name !== 'CROS')
            return {
              receiverDirectionId: targetReceiver?.direction?.id,
              receiverLengthId: targetReceiver['length']?.id,
              receiverPowerId: targetReceiver?.power?.id
            }
          else if (targetReceiver && record?.info?.orderType?.name === 'CROS')
            return {
              receiverDirectionId: targetReceiver?.direction?.id,
              receiverLengthId: targetReceiver['length']?.id
            }
          return null
        })
        .filter((receiver) => !!receiver)
    }

    const selectedProduct = products.find(({option}) => {
      if (
        values.chargerIds &&
        values.chargerIds.length &&
        !option.charge.find(({id}) => values.chargerIds?.includes(id))
      )
        return false
      if (values.hookIds && values.hookIds.length && !option.hook.find(({id}) => values.hookIds?.includes(id)))
        return false
      return true
    })

    Object.keys(values).forEach((key) => {
      if (!values[key]) values[key] = record[key]
    })

    setLoading(true)

    async function postCartProcess() {
      const res = await postCart({
        ...values,
        accIds: values?.accIds ? values?.accIds.filter((id) => id) : [],
        quantity,
        productId: selectedProduct?.id
      })
      const cartRes = await getAppCart()
      setCartTotal(cartRes.total)
      const productRes: any = await getProduct(selectedProduct?.id)

      values.quantity = productRes.quantity

      !isDirectBuy &&
        Modal.confirm({
          icon: <CheckCircleOutlined style={{color: '#52c41a'}} />,
          title: '장바구니에 추가되었습니다.',
          cancelText: '장바구니로 바로가기',
          okText: '계속 쇼핑하기',
          onCancel: () => navigator('../cart')
        })

      selectedId = res.id
    }

    try {
      // const {data} = await getAppCart()
      // const duplicatedCartItem = data.find((cartItem) => {
      //   if (cartItem.productInfo && cartItem.productInfo.id !== selectedProduct?.id) return false
      //   return true
      // })

      // if (duplicatedCartItem && !isDirectBuy) {
      //   Modal.confirm({
      //     icon: <ExclamationCircleOutlined />,
      //     content: '이미 장바구니에 존재하는 상품입니다. 추가로 담으시겠습니까?',
      //     cancelText: '아니오',
      //     okText: '예',
      //     onOk: async () => await postCartProcess()
      //   })
      // } else {
      await postCartProcess()
      // }
    } catch (e: any) {
      if (e.response.status === 409) {
        if (e.response.data.message === 'quantity_is_not_enough') {
          Modal.error({content: '수량이 적당하지 않아 구매가 불가능합니다.'})
        } else if (e.response.data.message === 'lessThan_safetyQuantity') {
          Modal.error({content: '수량이 적당하지 않아 구매가 불가능합니다.'})
        } else {
          if (isDirectBuy) {
            // const {data} = await getAppCart()
            // const duplicatedCartItem = data.find((cartItem) => {
            //   if (cartItem.productInfo && cartItem.productInfo.id !== record?.id) return false
            //   return true
            // })

            // if (duplicatedCartItem?.id) {
            //   selectedId = duplicatedCartItem?.id
            //   try {
            //     //await putCart(selectedId, values.quantity)
            //   } catch {}
            // } else {
            await postCartProcess()
            // }
          } else {
            const {data} = await getAppCart()
            const duplicatedCartItem = data.find((cartItem) => {
              if (cartItem.product && cartItem.product.id !== record?.id) return false
              return true
            })

            if (duplicatedCartItem?.id) {
              selectedId = duplicatedCartItem?.id
              try {
                await putCart(selectedId, {quantity: values.quantity})
              } catch {}
            } else {
              await postCartProcess()
            }
          }
        }
      } else if (e.response) {
        if (e.response.status === 400) Modal.error({content: '컬러를 선택하세요'})
        else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
      }
    } finally {
      setLoading(false)
      return selectedId
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  function receiverAdder() {
    const receiverIds: any = [null]
    const chargerIds: any = []
    const hookIds: any = []
    const accIds: any = []

    for (let i = 0; i < quantity; i++) {
      ;[...Array(orderType?.chargeCount)].forEach(() => {
        chargerIds.push(null)
      })
      ;[...Array(orderType?.hookCount)].forEach(() => {
        hookIds.push(null)
      })
      ;[...Array(orderType?.accCount)].forEach(() => {
        accIds.push(null)
      })
    }
    form.setFieldsValue({receiverIds, chargerIds, hookIds, accIds})

    setTotalQuantity(0)
    setQuantities({0: 0})
  }

  async function showOrderModal() {
    if (orderModalRef && orderModalRef.current) {
      const cartId = await handleFinish(form.getFieldsValue(), true)
      const {id} = await postOrderSheet({carts: [{id: cartId, quantity, couponIds: selectedCoupons}]})
      const record = await getOrderSheet(id)
      orderModalRef.current.showModal({type: 'show', record: {...record, id}})
    }
  }

  useEffect(() => {
    if (!selected.grade && !isRecordFetched.current) {
      if (recordRaw?.grades?.length && !recordRaw?.grades[0]?.id) recordRaw.grades = []
      setRecord({...recordRaw})
      isRecordFetched.current = true
    }
  }, [record])

  useEffect(() => {
    let max = {id: -1, quantity: -1}
    if (record.id && (selected.grade || !record.grades.length)) {
      ;(async function () {
        setColorLoading(true)

        const {data} = await getProducts({
          finalValueId: record.id,
          gradeId: selected.grade || null,
          orderTypeId: record?.info?.orderType?.id
        })

        for (const {colors} of data) {
          for (const {id} of colors) {
            const {data} = await getProducts({
              finalValueId: record.id,
              gradeId: selected.grade || null,
              colorId: id,
              orderTypeId: record?.info?.orderType?.id
            })
            // eslint-disable-next-line no-loop-func
            data.forEach(({products}) =>
              products.forEach((product: any) => {
                if (product.quantity > max.quantity) max = {id, quantity: product.quantity}
              })
            )
          }
        }

        setMostQuantityId(max.id)
        setRecord((prev) => ({...prev, colors: data[0].colors, colorChanges: data[0].colorChanges}))
        form.setFieldValue('colorId', '')

        setColorLoading(false)
      })()
    }
  }, [selected.grade, record.id])

  useEffect(() => {
    if (selected.color) {
      ;(async function () {
        setOptionLoading(true)
        let data
        const productsTmp: any = []

        const {data: dataWithSelectedColor} = await getProducts({
          finalValueId: record.id,
          gradeId: selected.grade,
          colorId: selected.color,
          orderTypeId: record?.info?.orderType?.id
        })

        if (!dataWithSelectedColor.length) {
          const {data: dataWithSelectedColor} = await getProducts({
            finalValueId: record.id,
            gradeId: selected.grade,
            colorId: mostQuantityId,
            orderTypeId: record?.info?.orderType?.id
          })
          data = dataWithSelectedColor
        } else data = dataWithSelectedColor

        const accs: any = []
        const receivers: any = []
        const charges: any = []
        const hooks: any = []
        let originPrice = -1

        for (const recordItem of data) {
          for (const product of recordItem.products) {
            try {
              const res: any = await getProduct(product.id)

              let uniqueCoupons: any = []
              try {
                const {coupons} = await getSheet({productId: product?.id})
                // uniqueCoupons = []
                // coupons.forEach(({coupon}) => {
                //   if (!uniqueCoupons.find((uniqueCoupon) => uniqueCoupon.id === coupon.id)) {
                //     uniqueCoupons.push(coupon)
                //   }
                // })
                if (coupons) uniqueCoupons = coupons
              } catch (error) {
                console.error(`Error fetching sheet for product ID ${product.id}:`, error)
                // 쿠폰이 없거나 오류가 나도 처리 진행
                uniqueCoupons = []
              }

              setCoupons(uniqueCoupons)

              if (user.isPricePublic) originPrice = res.originPrice

              if (!products.find((defProduct) => product.id === defProduct.id)) {
                productsTmp.push(res)
              }
              res?.option?.hook?.forEach((hookItem) => {
                if (!hooks?.find((hook) => hook.id === hookItem.id)) hooks.push(hookItem)
              })
              res?.option?.charge?.forEach((chargeItem) => {
                if (!charges?.find((charge) => charge.id === chargeItem.id)) charges.push(chargeItem)
              })
              res?.option?.receiver
                ?.filter((receiver) => !!receiver)
                .forEach((receiverItem) => {
                  if (!receivers?.find((receiver) => receiver.id === receiverItem.id)) {
                    receivers.push(receiverItem)
                  }
                })
              res?.option?.acc
                ?.filter((acc) => !!acc)
                .forEach((accItem) => {
                  if (!accs?.find((acc) => acc.id === accItem.id)) {
                    accs.push(accItem)
                  }
                })
            } catch (error) {
              console.error(`Error fetching sheet for product ID ${product.id}:`, error)
            }
          }
        }

        setOptionLoading(false)

        form.setFieldsValue({colorId: selected.color})
        if (productsTmp.length) {
          setProducts(productsTmp)
          setNoQuantityAgree(false)
        }

        setRecord((prev) => ({
          ...prev,
          accs,
          receivers,
          hooks,
          charges,
          originPrice
        }))
      })()
    } else {
      delete selected?.hook
      delete selected?.charge
      delete selected?.receiver
      delete selected?.acc
      form.setFieldsValue({
        accIds: [],
        hookIds: [],
        chargerIds: [],
        couponIds: [],
        receiverIds: []
      })
    }
  }, [selected.grade, selected.color, record.id])

  useEffect(() => {
    if (products.length) {
      const selectedProduct = products.find(({option}) => {
        if (selected.charge && !option.charge.find(({id}) => id === selected.charge)) return false
        if (selected.hook && !option.hook.find(({id}) => id === selected.hook)) return false
        if (selected.receiver && !option.receiver.find(({id}) => id === selected.receiver)) return false
        if (selected.acc && !option.acc.find(({id}) => id === selected.acc)) return false
        return true
      })
      if (selectedProduct) setProduct(selectedProduct)
    }
  }, [products, selected.acc, selected.color, selected.charge, selected.hook, selected.receiver])

  useEffect(() => {
    form.setFieldValue(['receiverIds', 0], null)
    for (let i = 0; i < orderType?.chargeCount * quantity; i++) {
      form.setFieldValue(['chargerIds', i], null)
    }
    for (let i = 0; i < orderType?.hookCount * quantity; i++) {
      form.setFieldValue(['hookIds', i], null)
    }
    for (let i = 0; i < orderType?.accCount * quantity; i++) {
      form.setFieldValue(['accIds', i], null)
    }
    form.setFieldValue('couponIds', [])
  }, [selected.color, orderType, quantity])

  useEffect(() => {
    if (product) {
      if (product?.onSale) {
        form.setFieldValue('quantity', 1)
        setQuantity(1)
      } else {
        form.setFieldValue('quantity', 0)
        setQuantity(0)
      }
    }
  }, [product])

  useEffect(() => {
    receiverAdder()
    setSelected((prev) => ({...prev, receiver: [], charger: [], hook: [], acc: []}))
  }, [quantity])

  useEffect(() => {
    if (selectedCoupons && selectedCharges) {
      for (const selectedCouponId of selectedCoupons) {
        const foundCouponItem = coupons.find(({id}) => selectedCouponId === id)
        if (foundCouponItem.isCharge) {
          form.setFieldValue(
            'chargeIds',
            selectedCharges.map(() => -1)
          )
          return
        }
      }
      form.setFieldValue('chargeIds', [])
    }
  }, [selectedCoupons, selectedCharges])

  return (
    <>
      <Space direction="vertical" style={{width: '100%', padding: '0 28px 28px 28px'}}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Description record={record} product={product} />
          {record.comment && (
            <Card size="small" title="상품설명" style={{marginTop: 20}}>
              {record.comment}
            </Card>
          )}
          <Row gutter={30} style={{marginTop: 20}}>
            {!!record?.grades?.length && (
              <Col span={8}>
                <Form.Item
                  label={
                    <>
                      <Tag color={process.env.REACT_APP_THEME_COLOR}>Step 1</Tag> 등급
                    </>
                  }
                  name="gradeId"
                >
                  <Select allowClear placeholder="등급을 선택하세요" onChange={(val) => setSelected({grade: val})}>
                    {record?.grades?.map((grade) => (
                      <Select.Option value={grade.id} key={grade.id}>
                        {grade.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={!!record?.grades?.length || record?.info?.orderType?.name !== 'CROS' ? 8 : 12}>
              <Form.Item
                label={
                  <>
                    <Tag color={process.env.REACT_APP_THEME_COLOR}>Step {!!record?.grades?.length ? 2 : 1}</Tag> 컬러
                  </>
                }
                name="colorId"
              >
                {colorLoading ? (
                  <Spin />
                ) : (
                  <Select
                    allowClear
                    placeholder="컬러를 선택하세요"
                    disabled={typeof selected.grade !== 'number' && !selected.grade && !!record?.grades?.length}
                    onChange={(val) => setSelected((prev) => ({grade: prev.grade, color: val}))}
                  >
                    {record?.colors?.map((color) => (
                      <Select.Option value={color.id} key={color.id}>
                        <Space style={{display: 'flex', alignItems: 'center'}}>
                          <ColorBox code={color.hex} />
                          {color.code}
                          {color.name}
                        </Space>
                      </Select.Option>
                    ))}
                    {record?.colorChanges?.map((color) => (
                      <Select.Option value={color.id} key={color.id}>
                        <Space style={{display: 'flex', alignItems: 'center'}}>
                          <ColorBox code={color.hex} />
                          <div>
                            <Tag>기타색상</Tag>
                            {color.code} {color.name}
                          </div>
                        </Space>
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={!!record?.grades?.length ? 8 : 12}>
              <Space>
                <Form.Item
                  label={
                    <>
                      <Tag color={process.env.REACT_APP_THEME_COLOR}>Step {!!record?.grades?.length ? 3 : 2}</Tag> 수량
                    </>
                  }
                  name="quantity"
                >
                  {optionLoading ? (
                    <Spin />
                  ) : (
                    <StyledInputNum
                      controls={false}
                      onClick={(e) => alert('수량 변경 시, 리시버 옵션을 다시 입력해야 합니다.')}
                      onChange={(val) => setQuantity(val as number)}
                      value={quantity}
                      disabled={(typeof selected.color !== 'number' && !selected.color) || !product?.onSale}
                      min={product?.onSale ? 1 : 0}
                      // max={
                      //   product?.quantity && product?.safetyQuantity < product?.quantity
                      //     ? product?.quantity - product?.safetyQuantity
                      //     : undefined
                      // }
                      isGradeExisted={!!record?.grades?.length}
                      addonAfter={
                        product &&
                        !product?.onSale && <Typography.Text type="danger">현재 구매가 불가능합니다.</Typography.Text>
                      }
                    />
                  )}
                </Form.Item>
              </Space>
            </Col>
            {user.isPricePublic && (
              <Col span={24} style={{marginBottom: 20}}>
                {typeof record.originPrice === 'number' && record.originPrice !== -1 && (
                  <Card>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>본 상품의 선택 된 옵션의 가격은 다음과 같습니다.</div>
                      <Space>
                        <div style={{color: process.env.REACT_APP_THEME_COLOR, fontSize: 20, fontWeight: 'bold'}}>
                          {(record.originPrice * quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                        원
                      </Space>
                    </div>
                  </Card>
                )}
              </Col>
            )}

            {!!record?.receivers?.length &&
              (orderType.receiverCount >= 1 || (orderType.receiverCount === 0.5 && quantity >= 2)) &&
              selected.color && (
                <Col span={24}>
                  <Form.Item
                    label={
                      <>
                        <Tag color={process.env.REACT_APP_THEME_COLOR}>추가옵션</Tag> 리시버 / 수량
                      </>
                    }
                  >
                    <Form.List name="receiverIds">
                      {(fields, {add, remove}) => (
                        <>
                          {fields.map((field, index) => (
                            <div
                              key={field.key}
                              style={{display: 'grid', gridTemplateColumns: '1fr auto auto', gap: 8}}
                            >
                              <Form.Item name={[field.name, 'receiverId']}>
                                <Select
                                  allowClear
                                  onChange={(val) =>
                                    setSelected((prev) => {
                                      if (prev?.receiver) {
                                        if (!!prev?.receiver[index]) prev.receiver[index] = val
                                        else prev.receiver[index] = val
                                      } else {
                                        prev.receiver = [val]
                                      }
                                      return {...prev}
                                    })
                                  }
                                >
                                  {record?.receivers
                                    ?.filter((receiver) => !!receiver)
                                    .map((receiver) => (
                                      <Select.Option value={receiver.id} key={receiver.id}>
                                        {receiver.materialDescription} ({receiver.direction?.name || '-'}/
                                        {receiver['length']?.name || '-'}/{receiver.power?.name || '-'})
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                              <InputNumber
                                style={{marginBottom: 24}}
                                value={quantities[index]}
                                onChange={(value) => handleQuantityChange(value, index)}
                                min={0}
                              />
                              <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  setTotalQuantity((prev) => prev - quantities[index])
                                  delete quantities[index]
                                  remove(field.name)
                                }}
                              >
                                삭제
                              </Button>
                            </div>
                          ))}
                          <Button
                            type="dashed"
                            onClick={() => {
                              quantities[Object.keys(quantities).length] = 0
                              add()
                            }}
                            disabled={fields.length >= maxReceivers}
                            icon={<PlusOutlined />}
                            style={{width: '100%', marginBottom: 12}}
                          >
                            리시버 추가
                          </Button>
                        </>
                      )}
                    </Form.List>

                    <div style={{display: 'flex', justifyContent: 'end'}}>
                      <Typography.Text type={totalQuantity !== maxReceivers ? 'danger' : undefined}>
                        선택 된 리시버 수량: {totalQuantity} / 주문 가능 리시버 수량: {maxReceivers}
                      </Typography.Text>
                    </div>
                  </Form.Item>
                </Col>
              )}

            {!!record?.accs?.length &&
              (orderType?.accCount >= 1 || (orderType?.accCount === 0.5 && quantity >= 2)) &&
              selected.color && (
                <Col span={24}>
                  <Form.Item
                    label={
                      <>
                        <Tag color={process.env.REACT_APP_THEME_COLOR}>추가옵션</Tag> 돔
                      </>
                    }
                  >
                    <Form.List name="accIds">
                      {(fields) =>
                        fields.map((value, index) => (
                          <Form.Item name={index}>
                            <Select
                              allowClear
                              onChange={(val) =>
                                setSelected((prev) => {
                                  if (prev?.acc) {
                                    if (!!prev?.acc[index]) prev.acc[index] = val
                                    else prev.acc[index] = val
                                  } else {
                                    prev.acc = [val]
                                  }
                                  return {...prev}
                                })
                              }
                            >
                              {record?.accs?.map((acc) => (
                                <Select.Option value={acc.id} key={acc.id}>
                                  {acc.materialDescription}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ))
                      }
                    </Form.List>
                  </Form.Item>
                </Col>
              )}

            {(orderType.hookCount >= 1 || (orderType.hookCount === 0.5 && quantity >= 2)) &&
              !!record?.hooks?.length &&
              selected.color && (
                <Col span={24}>
                  <Form.Item
                    label={
                      <>
                        <Tag color={process.env.REACT_APP_THEME_COLOR}>추가옵션</Tag> 후크
                      </>
                    }
                  >
                    <Form.List name="hookIds">
                      {(fields) =>
                        fields.map((value, index) => (
                          <Form.Item name={index}>
                            <Select
                              allowClear
                              onChange={(val) =>
                                setSelected((prev) => {
                                  if (prev?.hook) {
                                    if (!!prev?.hook[index]) prev.hook[index] = val
                                    else prev.hook[index] = val
                                  } else {
                                    prev.hook = [val]
                                  }
                                  return {...prev}
                                })
                              }
                            >
                              {record?.hooks?.map((hook) => (
                                <Select.Option value={hook.id} key={hook.id}>
                                  <Space style={{display: 'flex', alignItems: 'center'}}>
                                    <ColorBox code={hook.hex} />
                                    {hook.name}
                                  </Space>
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ))
                      }
                    </Form.List>
                  </Form.Item>
                </Col>
              )}
            {!!record?.charges?.length &&
              (orderType.chargeCount >= 1 || (orderType.chargeCount === 0.5 && quantity >= 2)) &&
              selected.color && (
                <Col span={24}>
                  <Form.Item
                    label={
                      <>
                        <Tag color={process.env.REACT_APP_THEME_COLOR}>추가옵션</Tag> 충전기
                      </>
                    }
                  >
                    <Form.List name="chargerIds">
                      {(fields) =>
                        fields.map((value, index) => (
                          <Form.Item name={index}>
                            <Select
                              disabled={selectedCharges?.find((id) => id === -1)}
                              allowClear
                              onChange={(val) =>
                                setSelected((prev) => {
                                  if (prev?.charger) {
                                    if (!!prev?.charger[index]) prev.charger[index] = val
                                    else prev.charger[index] = val
                                  } else {
                                    prev.charger = [val]
                                  }
                                  return {...prev}
                                })
                              }
                            >
                              {/* <Select.Option disabled value={-1}>
                                충전기 없음
                              </Select.Option> */}
                              {record?.charges?.map((charge) => (
                                <Select.Option value={charge.id} key={charge.id}>
                                  {charge.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ))
                      }
                    </Form.List>
                  </Form.Item>
                </Col>
              )}
            {selected.color && !!coupons?.length && (
              <Col span={24}>
                <Form.Item
                  label={
                    <Space>
                      <div>
                        <Tag color={process.env.REACT_APP_THEME_COLOR}>추가옵션</Tag> 쿠폰
                      </div>
                      <Popover
                        placement="topLeft"
                        content="충전기 제외 할인의 경우 장바구니에서 적용하실 수 없으므로, 지금 적용하셔야 합니다."
                      >
                        <Button shape="circle" icon={<QuestionOutlined />} />
                      </Popover>
                    </Space>
                  }
                  name="couponIds"
                >
                  <Select mode="multiple" allowClear>
                    {coupons?.map((coupon) => (
                      <Select.Option
                        disabled={coupon.bundleCount && coupon.bundleCount > quantity}
                        value={coupon.id}
                        key={coupon.id}
                      >
                        {!!coupon.isCharge && <Tag>충전기 제외 할인</Tag>}
                        {!!coupon.bundleCount && <Tag>수량 조건 : {coupon.bundleCount}</Tag>} {coupon.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {/* {selected.color && (
              <Col span={24}>
                <Form.Item label="인피니오 돔 종류 (오픈, 벤티드, 파워) / 사이즈(S, M, L) / 수량 기입" name="addText">
                  <Input />
                </Form.Item>
              </Col>
            )} */}

            {product &&
              (!product?.quantity || product?.safetyQuantity > product?.quantity || product?.quantity < quantity) &&
              orderType?.name !== 'ITE' && (
                <Col span={24}>
                  <Row style={{width: '100%', height: 50}}>
                    <Col span={24}>
                      <Checkbox checked={noQuantityAgree} onChange={(e) => setNoQuantityAgree(e.target.checked)}>
                        <Typography>재고 부족 품목으로, 입고 후 출고 예정인 점에 동의합니다.</Typography>
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
              )}
          </Row>
          <StyledSpace>
            <Form.Item>
              <Space>
                <Button
                  size="large"
                  shape="round"
                  type="primary"
                  onClick={() => form.submit()}
                  loading={loading}
                  disabled={isNotInputedAll}
                >
                  장바구니 (계속구매)
                </Button>
                <Button
                  size="large"
                  shape="round"
                  onClick={(e) => {
                    e.preventDefault()
                    setModalActions(['show'])
                    showOrderModal()
                  }}
                  loading={loading}
                  disabled={isNotInputedAll}
                >
                  바로구매
                </Button>
              </Space>
            </Form.Item>
          </StyledSpace>
        </Form>
      </Space>
      <OrderModal actions={modalActions} ref={orderModalRef} onAction={onModalAction} checkedList={[]} />
    </>
  )
}

const ProductModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '상품 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  return (
    <BasicTableModal
      maskClosable={false}
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={796}
      onAction={onAction}
      render={(type, record) => {
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledInputNum = styled(InputNumber)<{isGradeExisted: boolean}>`
  width: ${({isGradeExisted}: any) => (isGradeExisted ? 226.667 : 355)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LabelHorizontalFormItem = styled(Form.Item)`
  .ant-row.ant-form-item-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
  }

  .ant-col.ant-form-item-label {
    padding: 0;
  }
`

const ColorBox = styled.div<any>`
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 1px solid gray;
  background-color: ${({code}) => code};
`

export default ProductModal
