/* eslint-disable security/detect-unsafe-regex */
import React, {forwardRef, RefObject, useContext, useEffect, useRef, useState} from 'react'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import {IProduct} from '../../../../api/products'
import SimpleTitle from '../../../../components/common/SimpleTitle'
import styled from 'styled-components'
import {getAppCart, IPostCart, postCart, putCart} from '../../../../api/cart'
import ImageView from '../../../../components/ImageView'
import OrderModal from '../../cart/components/orderModal'
import PageContext from '../../../../contexts/PageContext'
import {getOrderSheet, postOrderSheet} from '../../../../api/orderSheets'
import {CheckCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router'
import {useRecoilValue} from 'recoil'
import {userState} from '../../../../states/user.state'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProduct>
  onAction: (type: ActionType, record: Partial<IProduct>) => void | Promise<void>
}

interface IPostCartWithQty extends IPostCart {
  quantity: number
}

function Description({
  record,
  setQuantity,
  quantity
}: {
  record: any
  setQuantity: React.Dispatch<React.SetStateAction<number>>
  quantity: number
}) {
  return (
    <Row gutter={25}>
      <Col>
        <ImageView src={record?.titleImage} width={292} height={292} />
      </Col>
      <Col style={{width: 400}}>
        <SimpleTitle title={record.name} subTitle={record?.brand?.name} />
        <Descriptions style={{width: '400px'}} size="small" column={1} bordered title="상품 정보">
          <Descriptions.Item span={1} label="브랜드">
            {record?.brand?.name || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="제품 유형">
            {(record?.orderType?.korean && `${record?.orderType?.name}(${record?.orderType?.korean})`) ||
              record?.orderType?.name ||
              '-'}
          </Descriptions.Item>
        </Descriptions>
        {typeof record.quantity !== 'number' ? (
          <Spin />
        ) : (
          <div>
            <Form.Item name="quantity" style={{marginTop: '25px'}}>
              <StyledInputNum
                controls={false}
                onChange={(val) => setQuantity(val as number)}
                value={quantity}
                disabled={!record.onSale}
                defaultValue={record.quantity ? 1 : 0}
                min={record?.onSale ? 1 : 0}
                // max={
                //   record?.quantity && record?.safetyQuantity < record?.quantity
                //     ? record?.quantity - record?.safetyQuantity
                //     : undefined
                // }
                addonBefore="수량"
                addonAfter={
                  !record?.onSale && <Typography.Text type="danger">현재 구매가 불가능합니다.</Typography.Text>
                }
              />
            </Form.Item>
            {/* 인피니오 돔 종류 (오픈, 벤티드, 파워) / 사이즈(S, M, L) / 수량 기입
            <Form.Item name="addText">
              <Input />
            </Form.Item> */}
          </div>
        )}
      </Col>
    </Row>
  )
}

function Show({record: {data: recordRaw}}: {record: any}) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const orderModalRef = useRef<BasicTableModalRef>(null)
  const {state, apiHandler} = useContext(PageContext)
  const [record, setRecord] = useState<any>([])
  const navigator = useNavigate()
  const user: any = useRecoilValue(userState)
  const [quantity, setQuantity] = useState<number>(-1)
  const [selected, setSelected] = useState<any>({
    receiver: [],
    charger: -1,
    hook: -1
  })
  const [receivers, setReceivers] = useState<any>([])
  const [noQuantityAgree, setNoQuantityAgree] = useState(false)

  async function handleFinish(values: IPostCartWithQty, isDirectBuy?: boolean) {
    let selectedId: number = 0

    Object.keys(values).forEach((key) => {
      if (!values[key]) values[key] = record[key]
    })

    if (values.chargerIds) {
      values.chargerIds = values.chargerIds.filter((id) => id !== -1)
      if (!values.chargerIds.length) delete values.chargerIds
    }

    setLoading(true)
    try {
      const reqData: any = {
        ...values,
        quantity,
        chargerIds: values?.chargerIds && Array.isArray(values?.chargerIds) ? values?.chargerIds : [values?.chargerIds],
        productId: record?.id,
        gradeId: record?.productGrade?.id,
        colorId: values?.colorId || record?.color?.id
      }
      if (!values?.chargerIds) delete reqData.chargerIds
      const res = await postCart(reqData as any)

      values.quantity = quantity

      try {
        try {
          await putCart(res.id, {quantity})
        } catch {}
        !isDirectBuy &&
          Modal.confirm({
            icon: <CheckCircleOutlined style={{color: '#52c41a'}} />,
            title: '장바구니에 추가되었습니다.',
            cancelText: '장바구니로 바로가기',
            okText: '계속 쇼핑하기',
            onCancel: () => navigator('../cart')
          })
      } catch (e: any) {
        if (e.response.status === 409) {
          Modal.confirm({
            icon: <CheckCircleOutlined style={{color: '#52c41a'}} />,
            title: '이미 존재하는 제품이므로, 수량을 추가하였습니다.',
            cancelText: '장바구니로 바로가기',
            okText: '계속 쇼핑하기',
            onCancel: () => navigator('../cart')
          })
        }
      }

      selectedId = res.id
    } catch (e: any) {
      if (e.response.status === 409) {
        if (e.response.data.message === 'quantity_is_not_enough') {
          Modal.error({content: '수량이 적당하지 않아 구매가 불가능합니다.'})
        } else if (e.response.data.message === 'lessThan_safetyQuantity') {
          Modal.error({content: '수량이 적당하지 않아 구매가 불가능합니다.'})
        } else {
          if (isDirectBuy) {
            const {data} = await getAppCart()
            const duplicatedCartItem = data.find((cartItem) => {
              if (cartItem.product && cartItem.product.id !== record?.id) return false
              return true
            })
            if (duplicatedCartItem?.id) {
              selectedId = duplicatedCartItem?.id
              try {
                await putCart(selectedId, {quantity})
              } catch {}
            } else Modal.error({title: '장바구니 데이터를 찾을 수 없음', content: '잠시 후 다시 시도해주세요'})
          } else {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              content: '이미 장바구니에 존재하는 상품입니다.',
              cancelText: '장바구니로 바로가기',
              okText: '계속 쇼핑하기',
              onCancel: () => navigator('../cart')
            })
          }
        }
      } else if (e.response) {
        if (e.response.status === 400) Modal.error({content: '컬러를 선택하세요'})
        else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
      }
    } finally {
      setLoading(false)
      return selectedId
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  async function showOrderModal() {
    if (orderModalRef && orderModalRef.current) {
      const cartId = await handleFinish(form.getFieldsValue(), true)
      const {id} = await postOrderSheet({carts: [{id: cartId, quantity}]})
      const record = await getOrderSheet(id)
      orderModalRef.current.showModal({type: 'show', record: {...record, id}})
    }
  }

  useEffect(() => {
    ;(async function () {
      setRecord({...recordRaw, originPrice: user.isPricePublic ? recordRaw.originPrice : -1})
    })()
  }, [])

  useEffect(() => {
    const receivers: any = []
    const chargerIds: any = []
    for (let i = 0; i < quantity; i++) {
      receivers.push({receiverLengthId: null, receiverDirectionId: null, receiverPowerId: null})
      if ((i + 1) % 2 === 0) chargerIds.push(null)
    }
    form.setFieldsValue({receivers, chargerIds})
  }, [quantity])

  useEffect(() => {
    setQuantity(record.quantity ? 1 : 0)

    if (record.option && record.option.receiver) {
      const receivers: any = []
      const receiverLengths: any = []
      const receiverDirections: any = []
      const receiverPowers: any = []

      form.setFieldValue('quantity', 1)

      for (const receiverItem of record.option.receiver) {
        if (Object.values(receiverItem).includes(null)) continue
        receivers.push(receiverItem)
        if (!receiverLengths?.find((length) => receiverItem.length && length?.id === receiverItem['length'].id))
          receiverItem['length'] && receiverLengths.push(receiverItem['length'])
        if (!receiverDirections?.find((direction) => direction?.id === receiverItem.direction?.id))
          receiverItem.direction && receiverDirections.push(receiverItem.direction)
        if (!receiverPowers?.find((power) => power?.id === receiverItem.power?.id))
          receiverItem.power && receiverPowers.push(receiverItem.power)
      }

      setReceivers(receivers)
      setRecord((prev) => ({
        ...prev,
        receiverDirections,
        receiverLengths,
        receiverPowers
      }))
      setSelected((prev) => ({...prev, receiver: []}))
    }
  }, [record.id])

  return (
    <>
      <Space direction="vertical" style={{width: '100%', padding: '0 23px'}}>
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Description record={record} setQuantity={setQuantity} quantity={quantity} />
          {record.comment && (
            <Card size="small" title="상품설명" style={{marginTop: 20}}>
              {record.comment}
            </Card>
          )}
          {user.isPricePublic && (
            <Col span={24} style={{marginBottom: 20}}>
              {typeof record.originPrice === 'number' && record.originPrice !== -1 && (
                <Card>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>본 상품의 가격은 다음과 같습니다.</div>
                    <Space>
                      <div style={{color: process.env.REACT_APP_THEME_COLOR, fontSize: 20, fontWeight: 'bold'}}>
                        {record.originPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                      원
                    </Space>
                  </div>
                </Card>
              )}
            </Col>
          )}

          {!!record?.option?.charge?.length && record.orderType.name !== 'Trials/Demos' && (
            <Col>
              <Form.Item label="충전기" name="chargerIds" style={{margin: 0}}>
                <StyledSelect allowClear>
                  {/* <Select.Option value={-1}>충전기 없음</Select.Option> */}
                  {record?.option?.charge?.map((charge) => (
                    <Select.Option value={charge.id} key={charge.id}>
                      {charge.name}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </Form.Item>
            </Col>
          )}
          <CenterRow>
            <CenterCol>
              <Space>
                {!!record?.option?.charge?.length && record.orderType.name === 'Trials/Demos' && quantity >= 1 && (
                  <Form.Item label="충전기" name="chargerIds" style={{margin: 0}}>
                    <StyledSelect allowClear>
                      {record?.option?.charge?.map((charge) => (
                        <Select.Option value={charge.id} key={charge.id}>
                          {charge.name}
                        </Select.Option>
                      ))}
                    </StyledSelect>
                  </Form.Item>
                )}

                {!!record?.option?.hook?.length && record.orderType.name === 'Trials/Demos' && quantity >= 1 && (
                  <Form.Item label="후크" name="hookIds" style={{margin: 0}}>
                    <Select style={{width: 200}} mode="multiple" allowClear>
                      {record?.option?.hook?.map((charge) => (
                        <Select.Option value={charge.id} key={charge.id}>
                          {charge.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Space>
            </CenterCol>
          </CenterRow>

          {!!record?.option?.receiver?.length &&
            !!record?.option?.receiver[0]['length'] &&
            record.orderType.name === 'Trials/Demos' &&
            quantity >= 1 && (
              <Form.Item label="리시버" style={{margin: 0}}>
                <Form.List name="receivers">
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map(({key, name}, index) => (
                        <Row gutter={20}>
                          <Col span={8}>
                            <Form.Item label={!index && '길이'} name={[index, 'receiverLengthId']}>
                              <Select
                                allowClear
                                onChange={(val) =>
                                  setSelected((prev) => {
                                    if (prev.receiver[index]) prev.receiver[index]['length'] = val
                                    else prev.receiver[index] = {length: val}
                                    return {...prev}
                                  })
                                }
                              >
                                {record?.receiverLengths?.map((length) => (
                                  <Select.Option value={length?.id} key={length?.id}>
                                    {length?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label={!index && '방향'} name={[index, 'receiverDirectionId']}>
                              <Select
                                allowClear
                                onChange={(val) =>
                                  setSelected((prev) => {
                                    if (prev.receiver[index]) prev.receiver[index].direction = val
                                    else prev.receiver[index] = {direction: val}
                                    return {...prev}
                                  })
                                }
                                disabled={
                                  selected?.receiver &&
                                  (!selected?.receiver[index] ||
                                    (selected?.receiver[index] && !selected?.receiver[index]['length']))
                                }
                              >
                                {record?.receiverDirections
                                  ?.filter(
                                    (direction) =>
                                      direction &&
                                      receivers
                                        ?.filter(
                                          (receiver) =>
                                            receiver['length'] &&
                                            selected?.receiver &&
                                            selected?.receiver[index] &&
                                            receiver['length'].id === selected?.receiver[index]['length']
                                        )
                                        .map((receiver) => (receiver.direction ? receiver.direction?.id : -1))
                                        .includes(direction.id)
                                  )
                                  ?.map(({id, name}) => (
                                    <Select.Option value={id} key={id}>
                                      {name.includes('위') ? 'Lt' : name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label={!index && '출력'} name={[index, 'receiverPowerId']}>
                              <Select
                                allowClear
                                onChange={(val) =>
                                  setSelected((prev) => {
                                    if (prev.receiver[index]) prev.receiver[index].power = val
                                    else prev.receiver[index] = {power: val}
                                    return {...prev}
                                  })
                                }
                                disabled={
                                  selected.receiver &&
                                  (!selected?.receiver[index] ||
                                    (selected?.receiver[index] && !selected?.receiver[index].direction))
                                }
                              >
                                {record?.receiverPowers
                                  ?.filter(
                                    (power) =>
                                      power &&
                                      receivers
                                        ?.filter(
                                          (receiver) =>
                                            receiver['length'] &&
                                            receiver.direction &&
                                            selected?.receiver &&
                                            selected?.receiver[index] &&
                                            receiver['length'].id === selected?.receiver[index]['length'] &&
                                            receiver.direction.id === selected?.receiver[index].direction
                                        )
                                        .map((receiver) => (receiver.power ? receiver.power?.id : -1))
                                        .includes(power.id)
                                  )
                                  ?.map(({id, name}) => (
                                    <Select.Option value={id} key={id}>
                                      {name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Form.Item>
            )}

          {record && (!record.quantity || record.safetyQuantity > record.quantity || record.quantity < quantity) && (
            <Col span={24}>
              <Row style={{width: '100%', height: 50}}>
                <Col span={24}>
                  <Checkbox checked={noQuantityAgree} onChange={(e) => setNoQuantityAgree(e.target.checked)}>
                    <Typography>재고 부족 품목으로, 입고 후 출고 예정인 점에 동의합니다.</Typography>
                  </Checkbox>
                </Col>
              </Row>
            </Col>
          )}

          <StyledSpace>
            <Form.Item>
              <Space>
                <Button
                  size="large"
                  shape="round"
                  type="primary"
                  onClick={() => handleFinish(form.getFieldsValue())}
                  loading={loading}
                  disabled={
                    !record.onSale ||
                    ((!record.quantity || record.safetyQuantity > record.quantity || record.quantity < quantity) &&
                      !noQuantityAgree)
                  }
                >
                  장바구니 (계속구매)
                </Button>
                <Button
                  size="large"
                  shape="round"
                  onClick={(e) => {
                    e.preventDefault()
                    setModalActions(['show'])
                    showOrderModal()
                  }}
                  loading={loading}
                  disabled={
                    !record.onSale ||
                    (record.quantity && record.safetyQuantity > record.quantity) ||
                    record.quantity < quantity
                  }
                >
                  바로구매
                </Button>
              </Space>
            </Form.Item>
          </StyledSpace>
        </Form>
      </Space>
      <OrderModal actions={modalActions} ref={orderModalRef} onAction={onModalAction} />
    </>
  )
}

const AccProductModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '상품 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  return (
    <BasicTableModal
      maskClosable={false}
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={763}
      onAction={onAction}
      render={(type, record) => {
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CenterRow = styled(Row)`
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CenterCol = styled(Col)`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledInputNum = styled(InputNumber)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledSelect = styled(Select)`
  width: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default AccProductModal
