import React from 'react'
import {ResultStatusType} from 'antd/lib/result'
import {FrownOutlined} from '@ant-design/icons'
import styled from 'styled-components'
import {Button, Layout, Result} from 'antd'

const {Content} = Layout

export default function ErrorLayout({status, message}: {status: ResultStatusType | string; message?: string}) {
  return (
    <Layout style={{minHeight: '100vh', minWidth: 1280}}>
      <Content style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Result
          status="error"
          icon={<FrownOutlined />}
          title={status}
          style={{
            width: '400px',
            padding: '40px 0 0 0',
            borderRadius: '10px',
            background: 'white',
            boxShadow: '2px 0 8px 0 rgb(29 35 41 / 5%)'
          }}
          subTitle={message ?? '서버와 통신할 수 없습니다'}
          extra={
            <ButtonStyled type="primary" shape="round" onClick={() => window.location.reload()}>
              새로 고침
            </ButtonStyled>
          }
        />
      </Content>
    </Layout>
  )
}

const ButtonStyled = styled(Button)`
  width: 100%;
  height: 70px;
  border-radius: 0 0 10px 10px;
`
