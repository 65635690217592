import React, {useState, useEffect} from 'react'
import {
  Avatar,
  Button,
  Card,
  Carousel,
  Checkbox,
  Col,
  Descriptions,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography
} from 'antd'
import {deleteVendor, getVendor, IVendor, patchVendor} from '../../../api/vendors'
import styled from 'styled-components'
import {ArrowLeftOutlined, ArrowRightOutlined, EditOutlined, ShopOutlined} from '@ant-design/icons'
import dayjs from 'dayjs'
import SimpleTitle from '../../../components/common/SimpleTitle'
import {getNotices} from '../../../api/notices'
import {useCookies} from 'react-cookie'
import getExpiredDate from '../../../libs/getExpiredDate'
import {getAppCart} from '../../../api/cart'
import {useRecoilState} from 'recoil'
import {cartTotalState} from '../../../states/cartTotal.state'
import {deleteAddress, getAddresss} from '../../../api/address'
import {postAddress} from '../../../api/address'
import DaumPostcodeEmbed from 'react-daum-postcode'
import AgreeMarketing from '../../../components/agreeContent/agreeMarketing'
import AgreeService from '../../../components/agreeContent/agreeService'
import PrivacyPolicy from '../../../components/agreeContent/privacyPolicy'
import {deleteAuth, getAuth, patchResetPassword} from '../../../api/auth'
import rules from '../../../libs/rules'
import {userState} from '../../../states/user.state'
import dayjsKorean from '../../../libs/DayjsKorean'
import {useForm} from 'antd/es/form/Form'

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 17
  }
}

function Show({data, setType}) {
  const [coupons, setCoupons] = useState<any>([])

  async function onFetchCoupons() {
    // const uniqueCoupons: any = []

    // data.coupons.forEach((coupon) => {
    //   if (!uniqueCoupons.find((uniqueCoupon) => uniqueCoupon.coupon.id === coupon.coupon.id)) uniqueCoupons.push(coupon)
    // })

    setCoupons(data.coupons)
  }

  useEffect(() => {
    onFetchCoupons()
  }, [])

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      <ProfileTitle>
        <ProfileArea>
          <Avatar
            size={128}
            style={{
              boxShadow: 'rgba(29, 35, 41, 0.05) 2px 4px 8px 5px',
              background: 'white',
              color: process.env.REACT_APP_THEME_COLOR
            }}
            icon={<ShopOutlined />}
          />
          <Space direction="vertical">
            <Space>
              <Typography.Title style={{color: 'white'}} level={2}>
                {data.name}
              </Typography.Title>
              <Button
                shape="circle"
                size="small"
                style={{marginBottom: '13px'}}
                icon={<EditOutlined />}
                onClick={() => setType('edit')}
              />
            </Space>
            <Typography>@{data.accountId}</Typography>
          </Space>
        </ProfileArea>
      </ProfileTitle>
      <div style={{marginTop: 70}} />
      <Typography.Title level={4} style={{marginBottom: -10}}>
        쿠폰
      </Typography.Title>
      {coupons.filter(({status}) => status === 'registered').length ? (
        <StyledCarousel
          arrows
          prevArrow={<Button type="link" shape="circle" icon={<ArrowLeftOutlined />} />}
          nextArrow={<Button type="link" shape="circle" icon={<ArrowRightOutlined />} />}
          dots={false}
          slidesToShow={4}
          style={{margin: '20px 0', display: 'flex', gap: 10}}
        >
          {coupons
            .filter(({status}) => status === 'registered')
            .map(({coupon, status, ...rest}, index) => (
              <div>
                <StyledCard
                  size="small"
                  title={
                    <Space>
                      <Typography.Text>{index + 1}.</Typography.Text>
                      <Typography.Text mark>
                        {coupon.type === 'bundle' && '번들'}
                        {coupon.type === 'price' && '금액할인'}
                        {coupon.type === 'special' && '특별할인'}
                      </Typography.Text>
                      <Typography.Text>{coupon.name}</Typography.Text>
                    </Space>
                  }
                >
                  <Descriptions column={1}>
                    {coupon.endDate ? (
                      <Descriptions.Item label="사용기간">
                        {`${dayjsKorean(coupon.startDate).format('YYYY/MM/DD')} ~ ${dayjsKorean(coupon.endDate).format(
                          'YYYY/MM/DD'
                        )}`}
                      </Descriptions.Item>
                    ) : (
                      <Descriptions.Item label="사용 시작일">
                        {dayjsKorean(coupon.startDate).format('YYYY/MM/DD')}
                      </Descriptions.Item>
                    )}
                    {coupon.brand && (
                      <Descriptions.Item label="사용가능 브랜드">
                        <Tag>{coupon.brand.name}</Tag>
                      </Descriptions.Item>
                    )}
                    {coupon.bundleCount && (
                      <Descriptions.Item label="구입 수량 조건">
                        <Tag>{coupon.bundleCount}</Tag>
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                  {coupon.isEven && <Typography.Text type="danger">* 짝수 수량에서만 사용 가능</Typography.Text>}
                </StyledCard>
              </div>
            ))}
        </StyledCarousel>
      ) : (
        <div style={{height: 170, border: '1px solid rgba(0,0,0,.06)'}}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
      <Descriptions column={1} bordered>
        <Descriptions.Item label="브랜드">
          {data.brands.map((brand) => (
            <Tag
              color={
                brand.name === 'Phonak'
                  ? '#88bc07'
                  : brand.name === 'Unitron'
                  ? '#00aeef'
                  : brand.name === 'Hansaton'
                  ? '#b9b9b9'
                  : process.env.REACT_APP_THEME_COLOR
              }
            >
              {brand.name}
            </Tag>
          )) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="메인 주소">{data.address || '-'}</Descriptions.Item>
        <Descriptions.Item label="추가 주소">
          {data.addressList.map((address) => (
            <>
              <Space>
                <Tag style={{marginRight: 0}} color={process.env.REACT_APP_THEME_COLOR}>
                  {address.name}
                </Tag>
                {`${address.address} ${address.addressDetail}`}
              </Space>
              <br />
            </>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="전화번호">{data.tel || '-'}</Descriptions.Item>
        <Descriptions.Item label="휴대폰 번호">{data.phone || '-'}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(data.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(data.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </Space>
  )
}

function Edit({loading, handleFinish, form, setType, address, setAddress}) {
  const [openPostcode, setOpenPostcode] = useState<boolean>(false)
  const [openSubPostcode, setOpenSubPostcode] = useState<boolean>(false)
  const [selectedAddressForm] = useForm()

  async function onAddressFetch() {
    const data = await getAddresss()
    setAddress(data.data)
  }

  function handleOpenPostcode() {
    setOpenPostcode(true)
  }

  function handleClosePostcode() {
    setOpenPostcode(false)
  }

  function handleOpenSubPostcode() {
    setOpenSubPostcode(true)
  }

  function handleCloseSubPostcode() {
    setOpenSubPostcode(false)
  }

  function handleAddAddress(data) {
    selectedAddressForm.resetFields()
    data.tel = data.tel.split('-').join('')
    data.title = data.name
    if (!data.addressDetail) data.addressDetail = ''
    setAddress([...address, {...data, crud: 'c'}])
    handleCloseSubPostcode()
  }

  function handleSelectAddress(data: any) {
    form.setFieldsValue({
      address: data.address,
      zipcode: data.zonecode
    })
    setOpenPostcode(false)
  }

  function handleSelectSubAddress(data: any) {
    selectedAddressForm.setFieldsValue({
      address: data.address,
      zipcode: data.zonecode
    })
  }

  useEffect(() => {
    onAddressFetch()
  }, [])

  return (
    <StyledForm layout="vertical" validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      <SimpleTitle title="프로필 수정" />

      <Form.Item name="id" label="ID" hidden>
        <Input disabled />
      </Form.Item>

      <Row>
        <Col span={24}>
          <Form.Item
            hidden
            label="이름"
            name="name"
            rules={[
              {required: true, message: '이름를 입력해주세요'},
              {type: 'string', min: 1, max: 40, message: '1 ~ 40자 이내로 입력해주세요.'}
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={19}>
          <Form.Item hidden label="메인 주소" name="address" rules={[{required: true, message: '주소를 입력해주세요'}]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item hidden label="메인 우편 번호" name="zipcode">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item hidden label=" " name="zipcode">
            <Button onClick={() => handleOpenPostcode()}>검색</Button>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name={['addressList', 0, 'id']} hidden>
        <Input />
      </Form.Item>

      <section style={{marginBottom: 10}}>
        <section style={{display: 'grid', gridTemplateColumns: '1fr 90px', marginBottom: 10}}>
          <Typography.Title level={5}>추가 주소 목록</Typography.Title>
          <Button size="small" onClick={() => handleOpenSubPostcode()}>
            주소 추가
          </Button>
        </section>
        <Table
          size="small"
          dataSource={address?.filter(({crud}) => crud !== 'd')}
          columns={[
            {
              dataIndex: 'name',
              title: '주소명'
            },
            {
              title: '주소',
              render: ({address, addressDetail}) => address + ' ' + (addressDetail || '')
            },
            {
              dataIndex: 'zipcode',
              title: '우편번호'
            },
            {
              dataIndex: 'tel',
              title: '전화번호'
            },
            {
              render: ({id: targetId}) => (
                <Popconfirm
                  title="이 주소를 삭제하시겠습니까?"
                  onConfirm={() => {
                    setAddress((prev) => [
                      ...prev
                        .filter((address) => (address.id === targetId && address.crud === 'c' ? false : true))
                        .map((address) => (address.id === targetId ? {...address, crud: 'd'} : address))
                    ])
                  }}
                >
                  <Button danger size="small">
                    삭제
                  </Button>
                </Popconfirm>
              )
            }
          ]}
        />
      </section>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="전화번호" name="tel" hidden>
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="휴대폰 번호" name="phone" hidden>
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="매니저" name="manager" rules={[{required: true, message: '매니저를 입력해주세요'}]} hidden>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="대지역"
            name="largeCategory"
            rules={[{required: true, message: '대지역 입력해주세요'}]}
            hidden
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="중지역"
            name="mediumCategory"
            rules={[{required: true, message: '중지역 입력해주세요'}]}
            hidden
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="소지역"
            name="smallCategory"
            rules={[{required: true, message: '소지역 입력해주세요'}]}
            hidden
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={2} offset={20}>
          <Button shape="round" style={{marginTop: '33px'}} type="primary" htmlType="submit" loading={loading}>
            수정
          </Button>
        </Col>

        {/* <Popconfirm title="정말로 탈퇴하시겠습니까? 이 작업은 되돌릴 수 없습니다." onConfirm={() => setType('delete')}>
          <Col span={2}>
            <Button shape="round" style={{marginTop: '33px'}} danger>
              탈퇴
            </Button>
          </Col>
        </Popconfirm> */}

        <Col span={2}>
          <Button shape="round" style={{marginTop: '33px'}} onClick={() => setType('show')}>
            취소
          </Button>
        </Col>
      </Row>

      <Modal title="주소 검색" open={openPostcode} onCancel={handleClosePostcode} footer={null}>
        <DaumPostcodeEmbed onComplete={handleSelectAddress} autoClose={false} />
      </Modal>

      <Modal
        footer={[
          <Button form="selectedAddressForm" type="primary" htmlType="submit">
            추가
          </Button>,
          <Button key="cancel" onClick={handleCloseSubPostcode}>
            취소
          </Button>
        ]}
        title="주소 검색"
        open={openSubPostcode}
        onCancel={handleCloseSubPostcode}
        width={1000}
      >
        <Form id="selectedAddressForm" layout="vertical" form={selectedAddressForm} onFinish={handleAddAddress}>
          <Row gutter={16}>
            <Col span={14}>
              <Form.Item label="추가 주소" name="address" required rules={[{required: true}]}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item label="추가 주소 상세" name="addressDetail">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label="추가 우편 번호" name="zipcode" required rules={[{required: true}]}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item label="추가 주소명" name="name" required rules={[{required: true}]}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item label="추가 주소 전화번호" name="tel" required rules={[{required: true}]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <DaumPostcodeEmbed onComplete={handleSelectSubAddress} autoClose={false} />
      </Modal>
    </StyledForm>
  )
}

function Vendor() {
  const [data, setData] = useState<IVendor | null>(null)
  const [address, setAddress] = useState<any>([])
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('show')
  const [notice, setNotice] = useState<any>({})
  const [appCookies, setAppCookies] = useCookies()
  const [, setCartTotal] = useRecoilState(cartTotalState)
  const [isAgreeVisible, setIsAgreeVisible] = useState(false)
  const [agree, setAgree] = useState({agreeMarketing: false, agreeService: false, privacyPolicy: false})
  const [isFirstSignIn, setIsFirstSignIn] = useState<boolean>(false)
  const [isInputed, setIsInputed] = useState({phone: false, code: false, newPw: false, newPwConfirm: false})
  const [user, setUser] = useRecoilState<any>(userState)

  async function onAddressFetch() {
    const data = await getAddresss()
    setAddress(data.data)
  }

  async function onNoticeFetch() {
    const res = await getNotices()
    setNotice(res.data.filter(({isFixation}) => isFixation)[0])
  }

  async function onFetch() {
    const data = await getVendor()
    const {total} = await getAppCart()
    setData(data)
    setCartTotal(total)
  }

  function handleCheckNoVisible() {
    const expires = getExpiredDate(7)
    setAppCookies('NOTICE_EXPIRES', true, {path: '/', expires})
    setNotice({})
  }

  async function onAgreeOkButtonClick() {
    const {agreeMarketing, agreeService, privacyPolicy} = agree
    if (agreeMarketing && agreeService && privacyPolicy) {
      try {
        await patchVendor({agreeMarketing, agreeService, privacyPolicy})
      } catch (e: any) {
        if (e.response && e.response.status !== 409) Modal.error({content: '에러가 발생했습니다.'})
      }
      setIsAgreeVisible(false)
    } else alert('모든 약관에 동의해주세요.')
  }

  async function handleFinish(values: any) {
    async function handleDefaultEdit() {
      try {
        const {
          id,
          name,
          zipcode,
          tel,
          phone,
          priceGroupId,
          code,
          largeCategory,
          mediumCategory,
          smallCategory,
          manager,
          enabled,
          ...rest
        } = values
        const data: any = {
          name,
          zipcode,
          tel,
          phone,
          priceGroupId: +priceGroupId,
          code,
          largeCategory,
          mediumCategory,
          smallCategory,
          manager,
          enabled
        }
        id && (await patchVendor({...rest, ...data}))

        console.log(address)

        const addTargetAddress = address.filter(({crud}) => crud === 'c')
        const deleteTargetAddress = address.filter(({crud}) => crud === 'd')

        if (addTargetAddress.length || deleteTargetAddress.length) {
          try {
            if (addTargetAddress.length) {
              for (const addAddress of addTargetAddress) {
                await postAddress(addAddress)
              }
            }
            if (deleteTargetAddress.length) {
              for (const deleteAddressItem of deleteTargetAddress) {
                await deleteAddress(deleteAddressItem.id)
              }
            }
            message.success('성공적으로 적용되었습니다.')
            setType('show')
          } catch (e: any) {
            Modal.error({
              title: '사용자 정보 수정은 완료되었으나, 추가 주소 등록 도중 오류가 발생하였습니다.',
              content: `${e.response.status}: ${e.response.data.message}`
            })
          }
        } else {
          message.success('성공적으로 적용되었습니다.')
          setType('show')
        }
      } catch (e: any) {
        if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    }

    async function handleDelete() {
      if (values.id) {
        await deleteVendor(values.id)
        message.success('성공적으로 적용되었습니다.')
        setType('show')
      }
    }

    setLoading(true)
    if (type === 'edit') await handleDefaultEdit()
    else if (type === 'delete') await handleDelete()
    setLoading(false)
  }

  async function handleResetPassword(values: any) {
    if (values.newPassword === values.newPasswordConfirm) {
      try {
        // await patchVendor({isFirstLogin: false})
        await patchResetPassword(values)
        Modal.success({
          content: '비밀번호가 변경되었습니다. 쿠키를 모두 지운 뒤 재로그인 해주세요.',
          okButtonProps: {
            onClick: async (e) => {
              e.preventDefault()
              try {
                await getAuth()
                setUser(false)
                Modal.destroyAll()
              } catch (e: any) {
                if (e.response.status === 401 && e.response.data.message === 'invalid_session') {
                  alert('쿠키가 지워지지 않았습니다.')
                } else {
                  setUser(false)
                  Modal.destroyAll()
                }
              }
            }
          }
        })
      } catch (e: any) {
        if (e?.response?.status === 409) {
          Modal.error({
            title: '존재하지 않는 판매자 계정',
            content: `알려진 판매자 계정이 없습니다.`
          })
        } else {
          Modal.error({
            title: '알 수 없는 오류가 발생했습니다.',
            content: '이 현상이 지속되면 관리자에게 문의하세요.'
          })
        }
      }
    } else {
      Modal.error({
        content: '비밀번호가 일치하지 않습니다.'
      })
    }
  }

  useEffect(() => {
    if (type === 'delete' && data) (async () => await handleFinish(data))()
    else if (type === 'show') {
      onFetch()
      onAddressFetch()
      if (!appCookies['NOTICE_EXPIRES']) onNoticeFetch()
    }
  }, [type, agree])

  useEffect(() => {
    if (data && (!data?.agreeMarketing || !data?.agreeService || !data?.privacyPolicy)) setIsAgreeVisible(true)
  }, [data])

  useEffect(() => {
    setIsFirstSignIn(!user?.isFirstLogin || false)
  }, [user])

  useEffect(() => form.setFieldsValue({...data, addressList: address}), [data, address])

  return type === 'edit' ? (
    <Edit
      loading={loading}
      handleFinish={handleFinish}
      form={form}
      setType={setType}
      address={address}
      setAddress={setAddress}
    />
  ) : data ? (
    <Space direction="vertical" style={{width: '100%'}}>
      <Show data={{...data, addressList: address}} setType={setType} />
      <Modal
        title={`[공지사항] ${notice?.title}`}
        open={notice && !!Object.keys(notice).length}
        footer={[<Checkbox onChange={handleCheckNoVisible}>7일 동안 보지 않기</Checkbox>]}
        onCancel={() => setNotice({})}
      >
        <HtmlContainer dangerouslySetInnerHTML={{__html: notice?.content}} />
      </Modal>
      <Modal
        closable={false}
        width={1000}
        title="약관에 동의가 필요합니다."
        open={isAgreeVisible}
        footer={[
          <Button type="primary" onClick={onAgreeOkButtonClick}>
            확인
          </Button>
        ]}
        onCancel={() => setNotice({})}
      >
        <Form>
          <AgreeContentContainer direction="vertical">
            <AgreeContent direction="vertical">
              <AgreeMarketing />
            </AgreeContent>
            <Form.Item>
              <Checkbox
                checked={agree.agreeMarketing}
                onChange={(e) => setAgree((prev) => ({...prev, agreeMarketing: e.target.checked}))}
              >
                마케팅 약관에 동의합니다.
              </Checkbox>
            </Form.Item>
          </AgreeContentContainer>
          <AgreeContentContainer direction="vertical">
            <AgreeContent direction="vertical">
              <AgreeService />
            </AgreeContent>
            <Form.Item>
              <Checkbox
                checked={agree.agreeService}
                onChange={(e) => setAgree((prev) => ({...prev, agreeService: e.target.checked}))}
              >
                서비스 이용약관에 동의합니다.
              </Checkbox>
            </Form.Item>
          </AgreeContentContainer>
          <AgreeContentContainer direction="vertical">
            <AgreeContent direction="vertical">
              <PrivacyPolicy />
            </AgreeContent>
            <Form.Item style={{marginBottom: 0}}>
              <Checkbox
                checked={agree.privacyPolicy}
                onChange={(e) => setAgree((prev) => ({...prev, privacyPolicy: e.target.checked}))}
              >
                개인정보 제공에 동의합니다.
              </Checkbox>
            </Form.Item>
          </AgreeContentContainer>
          <Card style={{borderRadius: 10, background: '#eaf4ff', border: 'none'}}>
            <Checkbox
              onChange={(e) =>
                e.target.checked
                  ? setAgree({agreeMarketing: true, agreeService: true, privacyPolicy: true})
                  : setAgree({agreeMarketing: false, agreeService: false, privacyPolicy: false})
              }
            >
              모두 동의합니다.
            </Checkbox>
          </Card>
        </Form>
      </Modal>
      <Modal title="비밀번호 재설정" open={isFirstSignIn} footer={false} onCancel={() => setNotice({})}>
        <Form
          {...layout}
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{remember: true}}
          onFinish={handleResetPassword}
        >
          <FormItemFixedWidth
            label="아이디 확인"
            name="code"
            rules={[
              {
                required: true,
                message: '아이디를 입력해주세요.'
              }
            ]}
          >
            <InputRoundPw
              onChange={(e) =>
                e.target.value
                  ? setIsInputed((prev) => ({...prev, code: true}))
                  : setIsInputed((prev) => ({...prev, code: false}))
              }
              placeholder="아이디를 입력해주세요."
            />
          </FormItemFixedWidth>

          <FormItemFixedWidth
            label="새로운 비밀번호"
            name="newPassword"
            rules={[
              {
                required: true,
                message: '비밀번호를 입력해주세요.'
              },
              ...rules.password
            ]}
          >
            <InputRoundPw
              onChange={(e) =>
                e.target.value
                  ? setIsInputed((prev) => ({...prev, newPw: true}))
                  : setIsInputed((prev) => ({...prev, newPw: false}))
              }
              placeholder="비밀번호를 입력해주세요."
            />
          </FormItemFixedWidth>

          <FormItemFixedWidth
            label="새로운 비밀번호 확인"
            name="newPasswordConfirm"
            rules={[
              {
                required: true,
                message: '비밀번호를 한번 더 입력해주세요.'
              },
              ...rules.password
            ]}
          >
            <InputRoundPw
              onChange={(e) =>
                e.target.value
                  ? setIsInputed((prev) => ({...prev, newPwConfirm: true}))
                  : setIsInputed((prev) => ({...prev, newPwConfirm: false}))
              }
              placeholder="비밀번호를 한번 더 입력해주세요."
            />
          </FormItemFixedWidth>

          <CancelSettingWrapper>
            <Button
              type="link"
              onClick={async () => {
                if (confirm('정말로 비밀번호 변경을 취소하시겠습니까?')) {
                  await deleteAuth()
                  setUser(false)
                }
              }}
            >
              로그인 페이지로 돌아가기
            </Button>
          </CancelSettingWrapper>

          <Form.Item style={{margin: '12px 0'}}>
            <SignInButton
              disabled={!isInputed.newPw || !isInputed.newPwConfirm}
              width={472}
              type="primary"
              htmlType="submit"
            >
              비밀번호 재설정
            </SignInButton>
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  ) : (
    <Skeleton />
  )
}

const StyledCarousel = styled(Carousel)`
  margin: 10px 0 20px 0;
  overflow: hidden;
  height: 170px;

  .slick-arrow {
    position: initial;
    height: 35px !important;
    border: 1px solid #dedede !important;
    color: rgba(0, 0, 0, 0.85) !important;
    visibility: visible !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    margin-top: 70px;

    .slick-prev {
      margin-right: 120px !important;
    }

    .slick-next {
      margin-left: 120px !important;
    }
  }
`

const StyledCard = styled(Card)`
  margin-right: 10px;
  height: 170px;
  border-radius: 10px;
`

const ProfileArea = styled(Space)`
  padding: 30px;
`

const ProfileTitle = styled.div`
  width: calc(100% + 48px);
  height: 100px;
  margin: -24px 0 0 -24px;
  background: ${process.env.REACT_APP_THEME_COLOR};
  border-radius: 10px 10px 0 0;
`

const StyledForm = styled(Form)`
  max-width: 800px;

  .ant-form-item-label {
    height: 33px;
  }
`

const AgreeContentContainer = styled(Space)`
  margin: 15px 0 10px 0;
`

const AgreeContent = styled(Space)`
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 15px;
  height: 300px;
  overflow: auto;
`

const HtmlContainer = styled.div`
  img {
    max-width: 480px;
  }
`

const FormItemFixedWidth = styled(Form.Item)`
  max-width: 472px;
  margin-bottom: 34px;

  .ant-col-4 {
    max-width: 258px;
  }

  .ant-col-17 {
    max-width: 258px;
  }

  .ant-form-item-required {
    &::before {
      margin: -10px;
      background: white;
    }
  }
`

const InputRoundPw = styled(Input.Password)`
  width: 472px;
  padding: 14px;
  border-radius: 8px;
`

const CancelSettingWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 6px 0 -9.75px 0;

  .ant-btn {
    height: 14px;
    color: #aeaeb2;
    font-size: 12px;
    padding: 0;

    span {
      text-decoration: underline;
    }
  }
`

const SignInButton = styled<any>(Button)`
  width: ${({width}: any) => width}px;
  height: 48px;
  padding: 12px 0;
  margin: 31.75px 0 auto 0;
  border-radius: 6px;
`

export default Vendor
